import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonLink from "../components/ButtonLink"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="mx-auto text-center mt-16 md:mt-32 mb-16">
      <h1 className="text-4xl font-heading">404: NOT FOUND</h1>
      <p>Sorry, this page doesn&#39;t exist.</p>
      <ButtonLink
        className=""
        url={`/`}
        text="Back to Home Page"
        classNames="mt-8 justify-center"
        color={"red"}
      />
    </div>
  </Layout>
)

export default NotFoundPage
